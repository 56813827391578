import React from "react";

export default function DynamicLabel(props) {
  return (
    <div>
      <span style={{ display: 'flex', flexDirection: 'row' }}>
        <label class="label" style={{ marginRight: '10px' }}>{props.label}:</label>
        <p style={{color: props.color}}>{props.fileName}</p>
      </span>
    </div>
  )
}