import React from "react";

// Change the instructions based on whether the user is on mobile
// webkitdirectory='multiple' doesn't allow mobile users to select a directory, so give them the next best option of multi-selecting the images they want
export default function HowTo(props) {
  return (
    <div class = "column m-6 box is-one-quarter is-info content">
      <h3 class = "is-size-3-tablet">See the Shapes</h3>
      <h4>Instructions:</h4>
      <ol>
        {props.isMobile ? 
          <li>Select reference images.</li> : 
          <li>Select reference images or directory.</li>
        }
        <li><strike>Choose the time interval.</strike></li>
        <li>Start your drawing session.</li>
      </ol>
    </div>
  )
}