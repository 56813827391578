import React from "react";
import {useState} from "react";

// Change the file upload attributes based on whether the user is on mobile
// webkitdirectory='multiple' doesn't allow mobile users to select a directory, so give them the next best option of multi-selecting the images they want
export default function FileUpload(props) {

  const [fileChecked, setFileChecked] = useState(props.isMobile);
  const handleClick = () => setFileChecked(!fileChecked)

  function renderFileSelect() {
    return (
      <input 
        class="file-input" 
        type="file" 
        name="fileList" 
        id="files" 
        webkitdirectory 
        multiple 
        onChange={props.confirmUpload}
      />
    )
  }

  function renderDirectory() {
    return (
      <input 
        class="file-input" 
        type="file" 
        name="fileList" 
        id="files" 
        webkitdirectory='multiple'
        onChange={props.confirmUpload}
      />
    )
  }

  function renderFileSelectCheckbox() {
    return (
      <div class="field">
        <label class="label" style={{display: "inline-block"}} />
        <span style={{valign: "middle", verticalAlign: "middle", textAlign: "center", width: "100%", height: "100%", paddingRight: "10px"}}>
          Choose individual images
        </span>
        <input type="checkbox" onClick={handleClick} checked={fileChecked} style={{valign: "middle", verticalAlign: "middle"}} />
      </div>
    )
  }

  return (
    <div>
      <div class="file is-large has-name is-boxed is-info block">
        <label class="file-label">
          <div>
            {fileChecked ? renderFileSelect() : renderDirectory()}
          </div>
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              {fileChecked ? "Select images" : "Select image folder"}
            </span>
          </span>
          <span class="file-name"></span>
        </label>
      </div>
      {!props.isMobile && renderFileSelectCheckbox()}
    </div>
  )
}