import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import Jimp from 'jimp';
import Panzoom from '@panzoom/panzoom';
import { isMobile } from 'react-device-detect';
import { saveAs } from 'file-saver';
import FileUpload from './components/FileUpload';
import DynamicLabel from './components/DynamicLabel';
import HowTo from './components/HowTo';
// import FilteredImage from './components/FilteredImage';
// import DrawingCanvas from './components/DrawingCanvas';
import * as fx from 'glfx-es6';
import $ from 'jquery';
import { MedianFilter } from './median';

// App settings
var DEBUG = false;
var FIT_IMAGE_TO_WINDOW = isMobile;

let GRID_DEFAULT_COLOR = "#FF00FF 0";
let GRID_INVERSE_COLOR = "#00FF00 0";
let GRID_DEFAULT_SPACE = "150";

let files = null;
let referenceImage = null;
let referenceImageUrl = null;
let time = null;
let backForthArray = null;
let traverser = 0;
let pauseTime = true; // TODO: this will need redone with the addition of state mgmt
let gestureSession;
let timer, minutes, seconds;
let darkModeBoolean = true;
let canonicalImage = null;
let canonicalImageWidth = null;
let canonicalImageHeight = null;
let oldImage = null;
let panzoomGlob = null;
var canvasA = null;
var canvasB = null;
var canvasBctx = null;
var textureA = null;
var medianFilter = null;
// let filteredImage = null;

// Filter defaults
var DEFAULT_DENOISE = 0.5;
var DEFAULT_THRESHOLD_MAX = 0;
var DEFAULT_UNSHARP_MASK_RADIUS = 100;
var DEFAULT_UNSHARP_MASK_STRENGTH = 1;
var DEFAULT_BRIGHTNESS = 0;
var DEFAULT_CONTRAST = 0;
var DEFAULT_CURVES = [[0, 0], [1, 1]];
var MEDIAN_MODES = ['quality', 'fast'];
var DEFAULT_MEDIAN_MODE = MEDIAN_MODES[0];
var DEFAULT_MEDIAN_MASKWIDTHHEIGHT = 20;
var DEFAULT_MEDIAN_KTH = 0.5;
var FILTER_STATUS_IDLE = '---';
var FILTER_STATUS_PENDING = 'pending...';
var FILTER_COLOR_DEFAULT = 'black';
var FILTER_COLOR_PENDING = 'red';
var denoiseIsApplied = false;
var unsharpMaskIsApplied = false;
var greyscaleIsApplied = false;
var keepEditsOnChange = false;
var brightnessContrastApplied = false;

////////////////////////////////////////////////////////////////////////////////
// Filter object
////////////////////////////////////////////////////////////////////////////////

function Filter(name, init, update, reset) {
  this.name = name;
  this.update = update;
  this.reset = reset;
  this.curves = [];
  init.call(this);
}

Filter.prototype.addCurves = function(name) {
  this.curves.push({ name: name });
};

var filter =
      new Filter('Curves', function() {
          this.addCurves('points');
      }, function() {
          // TODO: either remove this or get the current list of existing updates first
          // canvasA.draw(textureA).curves(this.points).update();
          // var image = document.getElementById("gestureImg");
          // image.src = canvasA.toDataURL('image/png');
      });

class FileInput extends Component {
  constructor() {
    super();
    this.processFile = this.processFile.bind(this);
    this.fileConfirm = this.fileConfirm.bind(this);
  }

  processFile() {
    files = document.getElementById("files").files;
    time = document.getElementById("timeForm").value / 1000;

    if (files.length === 0) {
      document.getElementsByClassName("file-name")[0].innerHTML = "No folder submitted";
    } else {
      backForthArray = [...Array(files.length).keys()]
      shuffleArray(backForthArray);
      var fileName = files[backForthArray[traverser]].name;
      ReactDOM.unmountComponentAtNode(document.getElementById('root'))
      ReactDOM.render(<SlideShow initialFileName={fileName} />, document.getElementById('root'));
    }
  }

  fileConfirm() {
    document.getElementsByClassName("file-name")[0].innerHTML = document.getElementById("files").files.length + " Files found";
  }

  render() {
    return (
      <div id='formDiv' class="columns is-mobile is-centered">
        <div class='rows'>
          <div class='row is-full'>
            <HowTo isMobile={isMobile} />
          </div>
          <div class='row is-full'>
            <div id="fileSubmit" class="column is-narrow m-6 box">
              <FileUpload isMobile={isMobile} confirmUpload={this.fileConfirm} />
              <div id="timeContainer" style={{paddingTop: "25px", paddingBottom: "25px"}}>
                <div id="timeSelect" class="select is-rounded is-medium container">
                  <select id='timeForm' disabled>
                    <option value="15000"> 15 seconds </option>
                    <option value="30000"> 30 seconds </option>
                    <option value="60000"> 1 minute </option>
                    <option value="120000" selected> 2 minutes </option>
                    <option value="180000"> 3 minutes </option>
                    <option value="300000"> 5 minutes </option>
                    <option value="600000"> 10 minutes </option>
                    <option value="900000"> 15 minutes </option>
                    <option value="1800000"> 30 minutes </option>
                    <option value="3600000"> 1 hour </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class='row is-full'>
            <div id="startButtonContainer">
              <button id="buttonStart" type="button" class="button is-medium is-info is-rounded" onClick={this.processFile}>Start</button>
            </div>
          </div>
        </div>
      </div>
      );
  }
}

class SlideShow extends Component {
  constructor(props) {
    super(props);
    this.nextImg = this.nextImg.bind(this);
    this.prevImg = this.prevImg.bind(this);
    this.stopSession = this.stopSession.bind(this);
    this.pauseSession = this.pauseSession.bind(this);
    this.imageTimer = this.imageTimer.bind(this);
    this.setup = this.setup.bind(this);
    this.trueNextImg = this.trueNextImg.bind(this);
    this.newBegin = this.newBegin.bind(this);
    this.darkMode = this.darkMode.bind(this);
    this.addRemoveGridlines = this.addRemoveGridlines.bind(this);
    this.resetCssTransforms = this.resetCssTransforms.bind(this);
    this.resetStatefulTransforms = this.resetStatefulTransforms.bind(this);
    this.greatReset = this.greatReset.bind(this);
    this.keepEditsOnNewImage = this.keepEditsOnImageChange.bind(this);
    this.greyscale = this.greyscale.bind(this);
    this.posterize = this.posterize.bind(this);
    this.threshold = this.threshold.bind(this);
    this.mirror = this.mirror.bind(this);
    this.scaleToFit = this.scaleToFit.bind(this);
    this.denoise = this.denoise.bind(this);
    this.unsharpMask = this.unsharpMask.bind(this);
    this.brightnessContrast = this.brightnessContrast.bind(this);
    this.median = this.median.bind(this);
    this.handleMedianCheck = this.handleMedianCheck.bind(this);
    this.medianConfig = this.medianConfig.bind(this);
    this.applyCurves = this.applyCurves.bind(this);
    this.resetCurves = this.resetCurves.bind(this);
    this.renderCurves = this.renderCurves.bind(this);
    this.applyWebglFilters = this.applyWebglFilters.bind(this);
    this.downloadImage = this.downloadImage.bind(this);
    this.setPanzoom = this.setPanzoom.bind(this);
    this.createCanvas = this.createCanvas.bind(this);
    this.create2dCanvas = this.create2dCanvas.bind(this);
    this.setModalState = this.setModalState.bind(this);
    this.loadReferenceImage = this.loadReferenceImage.bind(this);
    this.setReferenceImage = this.setReferenceImage.bind(this);
    this.setFileName = this.setFileName.bind(this);
    // this.toggleDrawingState = this.toggleDrawingState.bind(this);
    // this.setDrawingState = this.setDrawingState.bind(this);
    // this.createFilteredImage = this.createFilteredImage.bind(this);

    this.state = {
      modalIsOpen: false,
      referenceImage: "",
      fileName: this.props.initialFileName,
      filterStatus: FILTER_STATUS_IDLE,
      filterColor: FILTER_STATUS_IDLE ? FILTER_COLOR_DEFAULT : FILTER_COLOR_PENDING,
      // isDrawing: false,
      // filteredImage: null
    }
  }

  setModalState(state) {
    this.setState({ modalIsOpen: state })
  }

  setReferenceImage(image) {
    this.setState({ referenceImage: image })
  }

  setFileName(name) {
    this.setState({ fileName: name });
  }

  setFilterStatus(status) {
    this.setState({ filterStatus: status });
  }

  // toggleDrawingState() {
  //   var current = this.state.isDrawing;
  //   this.setState({ isDrawing: !current });
  // }

  // setDrawingState(state) {
  //   this.setState({ isDrawing: state });
  // }

  createCanvas() {
    try {
        if (!canvasA) {
          canvasA = fx.canvas();
          if (DEBUG) console.log("createCanvasA");
        }
    } catch (e) {
        alert(e);
        return;
    }
  }

  create2dCanvas() {
    try {
        if (!canvasB) {
          canvasB = document.createElement("canvas");
          canvasB.hidden = true;
          if (DEBUG) console.log("createCanvasB");
        }
    } catch (e) {
        alert(e);
        return;
    }
  }

  async componentDidMount() {
    if (DEBUG) console.log("componentDidMount()");
    this.setPanzoom();

    // Need to wait until openFile() finishes and loads the image
    var imageLoaded = await this.waitForImage();
    if (DEBUG) console.log("componentDidMount() -> imageLoaded");
    var image = document.getElementById('gestureImg');
    canonicalImageWidth = image.width;
    canonicalImageHeight = image.height;
    if (canvasA && imageLoaded && image) {
      if (DEBUG) console.log("componentDidMount(): initializing canvas/textures");

      textureA = canvasA.texture(image);
      textureA.loadContentsOf(image);
      canvasA.draw(textureA).update();
    }

    medianFilter = new MedianFilter();

    this.renderCurves();
  }

  timerStyleDesktop = {
    display: 'flex',
    margin: '10px'
  }

  timerStyleMobile = {
    display: 'flex',
    margin: '0'
  }
  
  waitForImage() {
    return new Promise(resolve => {
      setTimeout(function () {
        resolve(canonicalImage);
        if (DEBUG) console.log("waitForImage()");
      }, 1000);
    });
  }

  waitForImageChange() {
    return new Promise(resolve => {
      setTimeout(function () {
        resolve(oldImage !== canonicalImage);
        if (DEBUG) console.log("waitForImageChange()");
      }, 1000);
    });
  }

  openFile(file) {
    if (DEBUG) console.log("openFile()");
    oldImage = canonicalImage ?? null;
    var input = file;
    var reader = new FileReader();
    reader.onload = function () {
      var dataURL = reader.result;
      var output = document.getElementById('gestureImg');
      output.src = dataURL;
      canonicalImage = dataURL;
      if (DEBUG) console.log("openFile -> reader.onload");
    };
    reader.readAsDataURL(input);
    if (DEBUG) console.log("openFile -> readAsDataURL");
  };

  // Re-factor these next three functions into a single template function
  async nextImg() {
    if (DEBUG) console.log("nextImg");
    if (traverser === backForthArray.length - 1) {
      document.getElementById("last").className = "modal is-active"
      clearInterval(gestureSession);
    } else {
      clearInterval(gestureSession);
      traverser += 1;
      let file = files[backForthArray[traverser]];
      this.setFileName(file.name);
      this.openFile(file);
      if (!keepEditsOnChange) {
        this.resetStatefulTransforms();
      }
      await this.waitForImageChange();
      this.setup(false);
      if (keepEditsOnChange) {
        this.applyWebglFilters();
      }
    }
  }

  async trueNextImg() {
    if (traverser === backForthArray.length - 1) {
      document.getElementById("last").className = "modal is-active"
      clearInterval(gestureSession);
      // this.stopSession();
    } else {
      traverser += 1;
      let file = files[backForthArray[traverser]];
      this.setFileName(file.name);
      this.openFile(file);
      if (!keepEditsOnChange) {
        this.resetStatefulTransforms();
      }
      await this.waitForImageChange();
      this.setup(false);
      if (keepEditsOnChange) {
        this.applyWebglFilters();
      }
    }
  }

  async prevImg() {
    if (DEBUG) console.log("prevImg");
    if (traverser === 0) {
      document.getElementById("zeroth").className = "modal is-active"
      clearInterval(gestureSession);
    } else {
      clearInterval(gestureSession);
      traverser -= 1;
      let file = files[backForthArray[traverser]];
      this.setFileName(file.name);
      this.openFile(file);
      if (!keepEditsOnChange) {
        this.resetStatefulTransforms();
      }
      await this.waitForImageChange();
      this.setup(false);
      if (keepEditsOnChange) {
        this.applyWebglFilters();
      }
    }
  }

  pauseSession() {
    if (!pauseTime) {
      pauseTime = true;
      document.getElementById("pause").innerHTML = `<span class="icon">
        <i class="fas fa-play"></i>
      </span> </button>`
    } else {
      pauseTime = false;
      document.getElementById("pause").innerHTML = `<span class="icon">
        <i class="fas fa-pause"></i>
      </span> </button>`
    }
  }

  stopSession() {
    files = null;
    time = null;
    backForthArray = null;
    traverser = 0;
    pauseTime = false;
    darkModeBoolean = true;
    timer = 0;
    minutes = 0;
    seconds = 0;
    canonicalImage = null;
    oldImage = null;
    clearInterval(gestureSession);
    this.resetCssTransforms();
    this.resetStatefulTransforms();
    ReactDOM.unmountComponentAtNode(document.getElementById('root'))
    ReactDOM.render(<FileInput />, document.getElementById('root'));
  }

  darkMode () {
    if (darkModeBoolean) {
      document.getElementById('gestureWindow').style.backgroundColor = 'white'
      darkModeBoolean = false;
    } else {
      document.getElementById('gestureWindow').style.backgroundColor = 'black'
      darkModeBoolean = true;
    }
  }

  imageTimer() {
    if (!pauseTime) {
      if (timer <= 0) {
        timer = time;
        this.trueNextImg();
      }

      minutes = parseInt(timer / 60, 10)
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      document.getElementById("displayTimer").innerHTML = minutes + ":" + seconds;
      timer--;
    }
  }

  setup(timerReset) {
    // TODO: this will need redone with the addition of state mgmt
    // if (timerReset) {
    //   timer = time;
    //   setTimeout(this.imageTimer, 10);
    //   gestureSession = setInterval(this.imageTimer, 1000);
    // }

    // Enable if image zoom should reset with Next/Prev image buttons
    // if (panzoomGlob) {
    //   panzoomGlob.reset();
    // }

    var image = document.getElementById("gestureImg");
    if (canvasA && image) {
      if (textureA) textureA.destroy();
      
      textureA = canvasA.texture(image);
      textureA.loadContentsOf(image);
      canvasA.draw(textureA).update();
      canonicalImageWidth = image.width;
      canonicalImageHeight = image.height;
    } else {
      if (DEBUG) console.log("setup(): could not do initial texture load");
    }

    if (canvasB && canvasBctx) {
      canvasBctx.clearRect(0, 0, canvasB.width, canvasB.height);
    } else {
      if (DEBUG) console.log("setup(): could not clear canvasB");
    }
  }

  newBegin() {
    this.setup(true);
    document.getElementById('zeroth').className = 'modal';
  }

  modifyGrid() {
    let gridSpacePx = document.getElementById('gridSpace').value;

    var r = document.querySelector(':root');
    r.style.setProperty('--grid-spacing-x', 'transparent ' + gridSpacePx + 'px');
    r.style.setProperty('--grid-spacing-y', 'transparent ' + gridSpacePx + 'px');
  }

  invertGrid() {
    var r = document.querySelector(':root');
    var currentColor = getComputedStyle(r).getPropertyValue('--grid-color');
    r.style.setProperty('--grid-color',
      currentColor !== GRID_INVERSE_COLOR ?
        GRID_INVERSE_COLOR :
        GRID_DEFAULT_COLOR);
  }

  addRemoveGridlines() {
    let img = document.getElementById('gestureImg').src;
    if (document.getElementById('inputGridlines').checked === true) {
      document.getElementById('gridlineContainer').classList.add('bg-grid');
    } else if (document.getElementById('inputGridlines').checked === false) {
      document.getElementById('gridlineContainer').classList.remove('bg-grid');
    }

    // Need to reload the image for it to work
    document.getElementById('gestureImg').setAttribute("src", img);
  }

  async posterize() {
    if (DEBUG) console.log("posterize()");
    this.setFilterStatus(FILTER_STATUS_PENDING);
    let img = canonicalImage;
    await Jimp.read(img, (err, img) => {
      if (err) throw err;
      let value = document.getElementById('inputPosterize').value;
      img.posterize(Number(value), (res) => {
        this.setFilterStatus(FILTER_STATUS_IDLE)
      });
      img.getBase64(Jimp.AUTO, function(err, data) {
        var image = document.getElementById('gestureImg');
        image.setAttribute("src", data);
      });
    });
  }

  // TODO: operate on the canvas itself, not the raw img
  // save state of the image BEFORE thresholding, else use canonicalImage
  async threshold() {
    if (DEBUG) console.log("threshold()");
    this.setFilterStatus(FILTER_STATUS_PENDING);
    let img = canonicalImage;
    await Jimp.read(img, (err, img) => {
      if (err) throw err;
      let max = document.getElementById('inputThresholdMax').value;
      img.threshold({ max: Number(max) }, (res) => {
        this.setFilterStatus(FILTER_STATUS_IDLE)
      });
      img.getBase64(Jimp.AUTO, function(err, data) {
        var image = document.getElementById('gestureImg');
        image.setAttribute("src", data);
      });
    });
  }

  async mirror() {
    if (DEBUG) console.log("mirror()");
    this.setFilterStatus(FILTER_STATUS_PENDING);
    let img = document.getElementById('gestureImg').src;
    let mirrorOrientation = document.getElementById("inputMirror").value;
    await Jimp.read(img, (err, img) => {
      if (err) throw err;
      if (mirrorOrientation === "horizontalVertical") {
        img.flip(true, true, (res) => {
          this.setFilterStatus(FILTER_STATUS_IDLE)
        });
      } else if (mirrorOrientation === "horizontal") {
        img.flip(true, false, (res) => {
          this.setFilterStatus(FILTER_STATUS_IDLE)
        });
      } else if (mirrorOrientation === "vertical") {
        img.flip(false, true, (res) => {
          this.setFilterStatus(FILTER_STATUS_IDLE)
        });
      } else {
        if (DEBUG) console.log("mirror(): operation not supported");
        return;
      }
      img.getBase64(Jimp.AUTO, function(err, data) {
        var image = document.getElementById('gestureImg');
        image.setAttribute("src", data);
      });
    });
  }

  // TODO: doesn't save image state; operate on the canvas itself, not the raw img
  // save state of the image BEFORE thresholding, else use canonicalImage
  async scaleToFit() {
    // FIXME
    if (DEBUG) console.log("scaleToFit()");
    this.setFilterStatus(FILTER_STATUS_PENDING);
    let img = document.getElementById('gestureImg').src;
    await Jimp.read(img, (err, img) => {
      if (err) throw err;
      img.scaleToFit(1000, 1000, (res) => {
        this.setFilterStatus(FILTER_STATUS_IDLE)
      });
      img.getBase64(Jimp.AUTO, function(err, data) {
        var image = document.getElementById('gestureImg');
        image.setAttribute("src", data);
      });
      document.getElementById("inputScale").disabled = true;
    });
  }

  downloadImage() {
    let baseName = this.state.fileName.replace(/\.[^/.]+$/, "");
    let outputName = baseName + "_" + Math.floor(Date.now() / 1000).toString() + '.jpg';

    // var drawingCanvas = document.getElementById("drawingCanvas");
    // var canvas = this.state.isDrawing ? drawingCanvas : canvasA;
    var canvas = canvasA;

    canvas.toBlob(function(blob) {
      saveAs(blob, outputName);
    });
  }

  // Don't set it after re-drawing
  setPanzoom() {
    const img = document.getElementById('gestureImg');
    panzoomGlob = Panzoom(img, {
      minScale: 0.25,
      maxScale: 5,
      step: 0.25,
      panOnlyWhenZoomed: 0,
      cursor: 'zoom-in',
    });

    img.addEventListener('click', () => {
      img.style.pointer = 'zoom-out';
      panzoomGlob.zoomIn({ animate: true, step: 0 });
    });
    img.parentElement.addEventListener('wheel', panzoomGlob.zoomWithWheel);
  }

  applyWebglFilters() {
    // Check which filters were changed then apply all at once
    let denoise = Number(document.getElementById('inputDenoise').value);
    let unsharpRadius = Number(document.getElementById('inputUnsharpRadius').value);
    let unsharpStrength = Number(document.getElementById('inputUnsharpStrength').value);
    let contrast = Number(document.getElementById('inputContrast').value);
    let brightness = Number(document.getElementById('inputBright').value)
    let curvesApplied = !(JSON.stringify(filter["points"]) === JSON.stringify(DEFAULT_CURVES));

    if (DEBUG) {
      let currentVals = `
        Denoise: ${denoiseIsApplied}
        Unsharp: ${unsharpMaskIsApplied}
        Brightness/Contrast: ${brightnessContrastApplied}
        Curves Applied: ${curvesApplied}
        Greyscale: ${greyscaleIsApplied}
      `; 
      console.log(currentVals);
    }
    
    // Check for filters
    // TODO: figure out how to stack these without this giant conditional mess
    if (denoiseIsApplied && unsharpMaskIsApplied && brightnessContrastApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .brightnessContrast(brightness, contrast)
        .hueSaturation(0, -1)
        .curves(filter["points"])
        .update();
    } else if (denoiseIsApplied && unsharpMaskIsApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .hueSaturation(0, -1)
        .curves(filter["points"])
        .update();
    } else if (brightnessContrastApplied && unsharpMaskIsApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .hueSaturation(0, -1)
        .curves(filter["points"])
        .update();
    } else if (brightnessContrastApplied && denoiseIsApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .hueSaturation(0, -1)
        .curves(filter["points"])
        .update();
    } else if (denoiseIsApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .hueSaturation(0, -1)
        .curves(filter["points"])
        .update();
    } else if (unsharpMaskIsApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .hueSaturation(0, -1)
        .curves(filter["points"])
        .update();
    } else if (brightnessContrastApplied && curvesApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
      .brightnessContrast(brightness, contrast)
      .hueSaturation(0, -1)
      .curves(filter["points"])
        .update();
    } else if (denoiseIsApplied && unsharpMaskIsApplied && brightnessContrastApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .brightnessContrast(brightness, contrast)
        .hueSaturation(0, -1)
        .update();
    } else if (denoiseIsApplied && unsharpMaskIsApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .hueSaturation(0, -1)
        .update();
    } else if (brightnessContrastApplied && unsharpMaskIsApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .hueSaturation(0, -1)
        .update();
    } else if (brightnessContrastApplied && denoiseIsApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .hueSaturation(0, -1)
        .update();
    } else if (denoiseIsApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .hueSaturation(0, -1)
        .update();
    } else if (unsharpMaskIsApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .hueSaturation(0, -1)
        .update();
    } else if (brightnessContrastApplied && greyscaleIsApplied) {
      canvasA.draw(textureA)
      .brightnessContrast(brightness, contrast)
      .hueSaturation(0, -1)
        .update();
      } else if (curvesApplied && greyscaleIsApplied) {
        canvasA.draw(textureA)
        .curves(filter["points"])
        .hueSaturation(0, -1)
          .update();          
    } else if (denoiseIsApplied && unsharpMaskIsApplied && brightnessContrastApplied && curvesApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .brightnessContrast(brightness, contrast)
        .curves(filter["points"])
        .update();
    } else if (denoiseIsApplied && unsharpMaskIsApplied && curvesApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .curves(filter["points"])
        .update();
    } else if (brightnessContrastApplied && unsharpMaskIsApplied && curvesApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .curves(filter["points"])
        .update();
    } else if (brightnessContrastApplied && denoiseIsApplied && curvesApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .curves(filter["points"])
        .update();
    } else if (denoiseIsApplied && curvesApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .curves(filter["points"])
        .update();
    } else if (unsharpMaskIsApplied && curvesApplied) {
      canvasA.draw(textureA)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .curves(filter["points"])
        .update();
    } else if (brightnessContrastApplied && curvesApplied) {
      canvasA.draw(textureA)
      .brightnessContrast(brightness, contrast)
      .curves(filter["points"])
        .update();
    } else if (denoiseIsApplied && unsharpMaskIsApplied && brightnessContrastApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .brightnessContrast(brightness, contrast)
        .update();
    } else if (denoiseIsApplied && unsharpMaskIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .unsharpMask(unsharpRadius, unsharpStrength)
        .update();
    } else if (brightnessContrastApplied && unsharpMaskIsApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .update();
    } else if (brightnessContrastApplied && denoiseIsApplied) {
      canvasA.draw(textureA)
        .brightnessContrast(brightness, contrast)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .update();
    } else if (denoiseIsApplied) {
      canvasA.draw(textureA)
        .denoise(3 + 200 * Math.pow(1 - denoise, 4))
        .update();
    } else if (unsharpMaskIsApplied) {
      canvasA.draw(textureA)
        .unsharpMask(unsharpRadius, unsharpStrength)
        .update();
    } else if (brightnessContrastApplied) {
      canvasA.draw(textureA)
      .brightnessContrast(brightness, contrast)
      .hueSaturation(0, -1)
        .update();
    } else if (curvesApplied) {
      canvasA.draw(textureA)
      .curves(filter["points"])
        .update();
    } else if (greyscaleIsApplied) {
      canvasA.draw(textureA)
      .hueSaturation(0, -1)
        .update();
    }    
    else {
      // TODO: actually reset all WebGL filters if their values are 0
      if (DEBUG) console.log("applyWebglFilters(): no filters applied");
      canvasA.draw(textureA).update();
    }
    var image = document.getElementById("gestureImg");
    image.src = canvasA.toDataURL('image/png');
  }

  denoise() {
    let value = Number(document.getElementById("inputDenoise").value);
    if (value === 0) {
      denoiseIsApplied = false;
    } else {
      denoiseIsApplied = true;
    }

    this.applyWebglFilters();
  }

  unsharpMask() {
    let value = Number(document.getElementById("inputUnsharpStrength").value);
    if (value === 0) {
      unsharpMaskIsApplied = false;
    } else {
      unsharpMaskIsApplied = true;
    }

    this.applyWebglFilters();
  }

  greyscale() {
    let checked = document.getElementById("inputGrey").checked;
    if (checked) {
      greyscaleIsApplied = true;
    } else {
      greyscaleIsApplied = false;
    }

    this.applyWebglFilters();
  }

  brightnessContrast() {
    let contrast = Number(document.getElementById("inputContrast").value);
    let brightness = Number(document.getElementById("inputBright").value);
    if (contrast === 0 && brightness === 0) {
      brightnessContrastApplied = false;
    } else {
      brightnessContrastApplied = true;
    }

    this.applyWebglFilters();
  }

  // reset: reset to default values
  medianConfig(reset) {
    if (!medianFilter) {
      if (DEBUG) console.log("medianConfig(): !medianFilter");
      // noop: nothing to reset
    } else if (reset) {
      if (DEBUG) console.log("medianConfig(): reset");
      medianFilter.mode = DEFAULT_MEDIAN_MODE;
      medianFilter.maskWidth = DEFAULT_MEDIAN_MASKWIDTHHEIGHT;
      medianFilter.maskHeight = DEFAULT_MEDIAN_MASKWIDTHHEIGHT;
      medianFilter.kth = DEFAULT_MEDIAN_KTH;
    } else {
      if (DEBUG) console.log("medianConfig(): set values");
      let mode = document.getElementById('medianMode').value;
      let maskWidthHeight = document.getElementById('medianMaskWidthHeight').value;
      medianFilter.mode = String(mode);
      medianFilter.maskWidth = Number(maskWidthHeight);
      medianFilter.maskHeight = Number(maskWidthHeight);
      medianFilter.kth = Number(DEFAULT_MEDIAN_KTH);

      if (DEBUG) {
        let currentVals = `
          Mode: ${medianFilter.mode}
          Mask Width: ${medianFilter.maskWidth}
          Mask Height: ${medianFilter.maskHeight}
          Kth: ${medianFilter.kth}
        `; 
        console.log(currentVals);
      }
    }
  }

  handleMedianCheck = (e) => {
    this.setState({
      filterStatus: FILTER_STATUS_PENDING,
    }, this.median);
  }

  median() {
    try {
      if (!canvasBctx) {
        canvasBctx = canvasB.getContext('2d', { willReadFrequently: true });
      }
    } catch (e) {
        if (DEBUG) {
          console.log("median() initialization error: " + e);
        }
    }

    var width = textureA._.width;
    var height = textureA._.height;
    var image = document.getElementById("gestureImg");
    canvasB.width = width;
    canvasB.height = height;
    canvasBctx.clearRect(0, 0, width, height);
    canvasBctx.drawImage(image, 0, 0, width, height);
    var imageData = canvasBctx.getImageData(0, 0, width, height);
    canvasBctx.clearRect(0, 0, width, height);
    var filteredImageData = medianFilter.convertImage(imageData, width, height);
    canvasBctx.putImageData(filteredImageData, 0, 0);
    textureA.loadContentsOf(canvasB);
    canvasA.draw(textureA).update();
    image.src = canvasA.toDataURL('image/png');

    // Reset canvas
    canvasBctx.clearRect(0, 0, width, height);

    this.setFilterStatus(FILTER_STATUS_IDLE);
  }

  applyCurves() {
    this.applyWebglFilters();
  }

  resetCurves() {
    for (var i = 0; i < filter.curves.length; i++) {
      var curves = filter.curves[i];
      filter[curves.name] = [[0, 0], [1, 1]];
      curves.draw();
    }
    this.applyWebglFilters();
  }

  renderCurves() {
    var nextID = 0;
    // Generate the HTML for the controls
    var html = '<div class="item"><label class="label">' + filter.name + '</label><div class="contents">';
    for (let j = 0; j < filter.curves.length; j++) {
      let curves = filter.curves[j];
      curves.id = 'control' + nextID++;
      html += '<canvas class="curves" id="' + curves.id + '"></canvas>';
    }

    var item = $(html).appendTo('#curvesCollapse')[0];
    item.filter = filter;

    for (let j = 0; j < filter.curves.length; j++) {
      let curves = filter.curves[j];
      (function(curves, filter) {
        var canvas = $('#' + curves.id)[0];
        var c = canvas.getContext('2d');
        var w = canvas.width = 300; // $(canvas).width();
        var h = canvas.height = 250; // $(canvas).height();
        var start = 0;
        var end = 1;

        // Make sure there's always a start and end node
        function fixCurves() {
          if (point[0] === 0) start = point[1];
          if (point[0] === 1) end = point[1];
          var points = filter[curves.name];
          var foundStart = false;
          var foundEnd = false;
          for (var i = 0; i < points.length; i++) {
            var p = points[i];
            if (p[0] === 0) {
              foundStart = true;
              if (point[0] === 0 && p !== point) points.splice(i--, 1);
            } else if (p[0] === 1) {
              foundEnd = true;
              if (point[0] === 1 && p !== point) points.splice(i--, 1);
            }
          }
          if (!foundStart) points.push([0, start]);
          if (!foundEnd) points.push([1, end]);
        };

        // Render the curves to the canvas
        curves.draw = function() {
          var points = filter[curves.name];
          var map = fx.splineInterpolate(points);
          c.clearRect(0, 0, w, h);
          c.strokeStyle = '#4B4947';
          c.beginPath();
          for (let i = 0; i < map.length; i++) {
            c.lineTo(i / map.length * w, (1 - map[i] / 255) * h);
          }
          c.stroke();
          c.fillStyle = '#2793DA';
          for (let i = 0; i < points.length; i++) {
            var p = points[i];
            c.beginPath();
            c.arc(p[0] * w, (1 - p[1]) * h, 3, 0, Math.PI * 2, false);
            c.fill();
          }
        };

        // Allow the curves to be manipulated using the mouse
        var dragging = false;
        var point;
        function getMouse(e) {
          var offset = $(canvas).offset();
          var x = Math.max(0, Math.min(1, (e.pageX - offset.left) / w));
          var y = Math.max(0, Math.min(1, 1 - (e.pageY - offset.top) / h));
          return [x, y];
        }
        $(canvas).mousedown(function(e) {
          var points = filter[curves.name];
          point = getMouse(e);
          for (var i = 0; i < points.length; i++) {
            var p = points[i];
            var x = (p[0] - point[0]) * w;
            var y = (p[1] - point[1]) * h;
            if (x * x + y * y < 5 * 5) {
              point = p;
              break;
            }
          }
          if (i === points.length) points.push(point);
          dragging = true;
          fixCurves();
          curves.draw();
          filter.update();
        });
        $(document).mousemove(function(e) {
          if (dragging) {
            var p = getMouse(e);
            point[0] = p[0];
            point[1] = p[1];
            fixCurves();
            curves.draw();
            filter.update();
          }
        });
        $(document).mouseup(function() {
          dragging = false;
        });

        // Set the initial curves
        filter[curves.name] = [[0, 0], [1, 1]];
        curves.draw();
    })(curves, filter);
    }    
  }

  modeSelect = async (e) => {
    // TODO: track previous mode as state; if it's anything other than 0, run greatReset() before setting another mode
    let mode = Number(e.target.value)
    if (mode === 1) { // shadow shapes study
      document.getElementById('inputContrast').value = 5;
      document.getElementById('inputGrey').checked = true;
      document.getElementById('inputPosterize').value = 3;
      await this.posterize()
      // document.getElementById('inputMode').disabled = true;
    } else if (mode === 2) { // notan study
      document.getElementById('inputContrast').value = 3;
      document.getElementById('inputGrey').checked = true;
      // document.getElementById('inputMode').disabled = true;
    }
  }

  // Load the current image by default
  loadReferenceImage(callback) {
    referenceImage = document.getElementById("referenceFileInput").files[0];

    if (referenceImage.length === 0) {
      if (DEBUG) console.log("No images selected.");
    } else {
      let input = referenceImage;
      let reader = new FileReader();
      reader.onload = function () {
        let dataURL = reader.result;
        let output = document.getElementById('referenceImg');
        output.src = dataURL;
        referenceImageUrl = URL.createObjectURL(referenceImage);
        callback(referenceImageUrl);
      };
      reader.readAsDataURL(input);
    }
  }

  keepEditsOnImageChange() {
    if (DEBUG) console.log("keepEditsOnNewImage()");
    let checked = document.getElementById("inputKeepEdits").checked;
    if (checked) {
      keepEditsOnChange = true;
    } else {
      keepEditsOnChange = false;
    }

    //this.applyWebglFilters();
  }

  async greatReset() {
    // Need to reopen the file in case posterization modified the image buffer
    if (DEBUG) console.log("greatReset()");
    let file = files[backForthArray[traverser]];
    this.setFileName(file.name);
    this.openFile(file);
    this.resetCssTransforms();
    this.resetStatefulTransforms();
    await this.waitForImageChange();
    this.setup(false);
  }

  // Changes applied only using CSS
  resetCssTransforms() {
    document.getElementById('gestureImg').style.filter = ''
    document.getElementById('gridSpace').value = GRID_DEFAULT_SPACE;

    var r = document.querySelector(':root');
    r.style.setProperty('--grid-color', GRID_DEFAULT_COLOR);
    r.style.setProperty('--grid-spacing-x', 'transparent ' + GRID_DEFAULT_SPACE + 'px');
    r.style.setProperty('--grid-spacing-y', 'transparent ' + GRID_DEFAULT_SPACE + 'px');
  }

  // Changes that modify the image directly (i.e. using Jimp)
  resetStatefulTransforms() {
    // document.getElementById('inputMode').value = 0;
    // document.getElementById('inputMode').disabled = false;
    document.getElementById('inputScale').disabled = false;
    document.getElementById('inputPosterize').value = 0;
    document.getElementById('inputMirror').value = 0;
    document.getElementById('inputGridlines').checked = false;
    document.getElementById('invertGridlines').checked = false;
    document.getElementById('gridlineContainer').classList.remove('bg-grid');

    // Reset WebGL filters
    document.getElementById('inputGrey').checked = false;
    document.getElementById('inputDenoise').value = DEFAULT_DENOISE;
    document.getElementById('inputUnsharpRadius').value = DEFAULT_UNSHARP_MASK_RADIUS;
    document.getElementById('inputUnsharpStrength').value = DEFAULT_UNSHARP_MASK_STRENGTH;
    document.getElementById('inputContrast').value = DEFAULT_CONTRAST;
    document.getElementById('inputBright').value = DEFAULT_BRIGHTNESS;
    document.getElementById('inputThresholdMax').value = DEFAULT_THRESHOLD_MAX;
    denoiseIsApplied = false;
    unsharpMaskIsApplied = false;
    brightnessContrastApplied = false;
    greyscaleIsApplied = false;

    // Drawing
    //this.setDrawingState(false);
    //document.getElementById('inputDraw').checked = false;
    
    // Median filter reset logic
    document.getElementById('medianMode').value = DEFAULT_MEDIAN_MODE;
    document.getElementById('medianMaskWidthHeight').value = DEFAULT_MEDIAN_MASKWIDTHHEIGHT;

    this.medianConfig(true);
    if (canvasB && canvasBctx) {
      if (DEBUG) console.log("resetStatefulTransforms(): clearing canvasB");
      canvasBctx.clearRect(0, 0, canvasB.width, canvasB.height);
      canvasBctx.reset();
    } else {
      if (DEBUG) console.log("resetStatefulTransforms(): could not clear canvasB");
    }

    // Reset all curves
    this.resetCurves();
  }

  // createFilteredImage(imgSrc) {
  //   return <FilteredImage fileSrc={imgSrc} maxHeight={FIT_IMAGE_TO_WINDOW ? '95vh' : '100%'} />;
  // }

  render() {
    return (
      <div id="gestureWindow" style={{ top: 0, bottom: '100%', height: '100vh', background: 'black' }}>
        <div style={{ position: 'fixed', bottom: 0, right: 0 }}>
          {/* TODO: this will need redone with the addition of state mgmt */}
          {/* <span id="displayTimer" class="tag is-large is-dark" style={isMobile ? this.timerStyleMobile : this.timerStyleDesktop}></span> */}
        </div>
        <div style={{ padding: 0, margin: 'auto auto', justifyContent: 'center', top: 0, bottom: 0, height: '100vh', display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
          <div id="gridlineContainer">
            {this.createCanvas()}
            {this.create2dCanvas()}
            <img src={canonicalImage ?? this.openFile(files[backForthArray[traverser]])} alt="unsupported" id="gestureImg" style={{ padding: 0, display: 'block', maxHeight: FIT_IMAGE_TO_WINDOW ? '95vh' : '100%', maxWidth: '100%', filter: ''}} />
          </div>
          {this.setup(true)}
        </div>
        <div id="modalDiv">
          <Lightbox
            id="referenceModal"
            open={this.state.modalIsOpen}
            close={() => this.setModalState(false)}
            slides={[{ src: this.state.referenceImage }]}
          />
        </div>
        <div style={{ position: 'fixed', top: 0, left: 0 }} >
          <section className="section" style={{ paddingTop: isMobile ? '6px' : '20px', paddingLeft: isMobile ? '14px' : '20px' }}>
            <div className="container">
              <div className="columns is-multiline">
                <Collapse title="Advanced" isWide={true} isExpanded={!isMobile}>
                  <div class="control" id="statusLabelCollapse" style={{ paddingBottom: isMobile ? '6px' : '10px' }}>
                    <DynamicLabel label="Status" fileName={this.state.filterStatus} color={this.state.filterColor} />
                  </div>
                  <div class="control" id="fileLabelCollapse" style={{ paddingBottom: isMobile ? '6px' : '10px' }}>
                    <DynamicLabel label="File" fileName={this.state.fileName} color={FILTER_COLOR_DEFAULT} />
                  </div>
                  <div class="control" id="curvesCollapse"></div>
                  <div id="curvesButtons">
                    <button class="button is-info" id = 'curvesApplyButton' type="checkbox" onClick = {this.applyCurves} style={{ margin: '10px' }}>Apply</button>
                    <button class="button is-info" id = 'curvesResetButton' type="checkbox" onClick = {this.resetCurves} style={{ margin: '10px' }}>Reset</button>
                  </div>
                  <div class="control" id="medianCollapse" style={{ paddingTop: isMobile ? '6px' : '20px' }}></div>
                  <div class="field">
                    <label class="label">Median filter</label>
                    <div id="medianOptions">
                      <div class="field">
                        <label for="medianMode" class="label">Mode</label>
                        <select id="medianMode" name="medianMode" onChange={() => this.medianConfig(false)} style={{ margin: '10px' }}>
                          <option value='quality'>Quality</option>
                          <option value='fast'>Fast</option>
                        </select>
                      </div>
                      <div class="field">
                        <label class="label">Mask Size</label>
                        <div class="control">
                          <input id = 'medianMaskWidthHeight' class="slider" min="2" max="100" step="1" defaultValue={DEFAULT_MEDIAN_MASKWIDTHHEIGHT} type="range" width = "300px" onChange = {() => this.medianConfig(false)} style={{ margin: '10px' }}></input>
                        </div>
                      </div>
                      <div class="field">
                        <div class="control">
                          <button class="button is-info" id = 'medianApply' type="checkbox" onClick = {e => this.handleMedianCheck(e)} style={{ margin: '10px' }}>Apply</button>
                        </div>
                      </div>
                    </div>
                    {/* <div class="field">
                      <label class="label">Drawing Mode (beta)</label>
                      <div class="control">
                        <input id = 'inputDraw' type="checkbox" onClick = {this.toggleDrawingState}></input>
                      </div>
                    </div> */}
                  </div>
                  <div class="field">
                      <label class="label">Keep edits on image change</label>
                      <div class="control">
                        <input id = 'inputKeepEdits' type="checkbox" onClick = {this.keepEditsOnImageChange}></input>
                      </div>
                    </div>
                </Collapse>
              </div>
            </div>
          </section>
        </div>
        <div style={{ position: 'fixed', bottom: 0, left: 0 }} >
          <section className="section" style={{ paddingBottom: isMobile ? '6px' : '20px', paddingLeft: isMobile ? '14px' : '20px' }}>
            <div className="container">
              <div className="columns is-multiline">
                <Collapse title="Reference" isWide={true} isExpanded={false} isOnBottom={true}>
                  <img src="" alt="unsupported" id="referenceImg" style={{ padding: '10px', display: 'block', maxHeight: '100%', maxWidth: '100%' }} />
                  <div id="referenceButtons">
                    <input class="file-input" type="file" name="fileList" id="referenceFileInput" webkitdirectory onChange={() => this.loadReferenceImage(this.setReferenceImage)} hidden />
                    <button class="button is-info" id = "loadRefButton" type="checkbox" onClick = {() => document.getElementById("referenceFileInput").click()} style={{ margin: "auto 10px" }}>Load Image</button>
                    <button class="button is-info" id = "openModalButton" type="checkbox" onClick={() => this.setModalState(true)} style={{ margin: "auto 10px" }}>Open Modal</button>
                  </div>
                </Collapse>
              </div>
            </div>
          </section>
        </div>
        <div style={{ position: 'fixed', top: 0, right: 0 }} >
          <section className="section" style={{ paddingTop: isMobile ? '6px' : '20px', paddingRight: isMobile ? '14px' : '20px' }}>
            <div className="container">
              <div className="columns is-multiline">
                <Collapse title="Image Editor" isExpanded={!isMobile}>
                  <div>
                    <div class="field">
                      <label class="label">Scale</label>
                      <div class="control">
                        <button class="button is-info" id="inputScale" type="checkbox" onClick = {this.scaleToFit} style={{ margin: '10px' }}>Scale</button>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Greyscale</label>
                      <div class="control">
                        <input id = 'inputGrey' type="checkbox" onClick = {this.greyscale}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Gridlines</label>
                      <div class="checkParent">
                        <div class="checkChild">
                          <label class="label">Enable</label>
                          <input id = 'inputGridlines' type="checkbox" onClick = {this.addRemoveGridlines}></input>
                        </div>
                        <div class="checkChild">
                          <label class="label">Invert</label>
                          <input id = 'invertGridlines' type="checkbox" onClick = {this.invertGrid}></input>
                        </div>
                      </div>
                      <div class="control">
                        <input id = 'gridSpace'  class = "slider" min = '50' max="500" step="50" defaultValue = '150' type="range" width = '300px'onChange = {this.modifyGrid}></input>
                      </div>
                    </div>                 
                    <div class="field">
                      <label class="label">Contrast</label>
                      <div class="control">
                        <input id = 'inputContrast'  class = "slider" min = '-1' max="1" step="0.1" defaultValue = '0' type="range" width = '300px'onChange = {this.brightnessContrast}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Brightness</label>
                      <div class="control">
                        <input id = 'inputBright'  class = "slider" min = '-1' max="1" step="0.1" defaultValue = '0' type="range" width = '100px'onChange = {this.brightnessContrast}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Threshold Max</label>
                      <div class="control">
                        <input id = 'inputThresholdMax' class = "slider" min = '0' max="255" step="1" defaultValue = {DEFAULT_THRESHOLD_MAX} type="range" width = '100px' onChange = {this.threshold}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Denoise</label>
                      <div class="control">
                        <input id = 'inputDenoise' class = "slider" min = '0' max="1" step="0.1" defaultValue = {DEFAULT_DENOISE} type="range" width = '100px' onChange = {this.denoise}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Unsharp Radius</label>
                      <div class="control">
                        <input id = 'inputUnsharpRadius' class = "slider" min = '0' max="200" step="1" defaultValue = {DEFAULT_UNSHARP_MASK_RADIUS} type="range" width = '100px' onChange = {this.unsharpMask}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Unsharp Strength</label>
                      <div class="control">
                        <input id = 'inputUnsharpStrength' class = "slider" min = '0' max="5" step="0.01" defaultValue = {DEFAULT_UNSHARP_MASK_STRENGTH} type="range" width = '100px' onChange = {this.unsharpMask}></input>
                      </div>
                    </div>
                    <div class="field">
                      <label for="inputMirror" class="label">Mirror/Flip</label>
                      <select id="inputMirror" name="inputMirror" onChange={this.mirror} style={{ margin: '10px' }}>
                        <option hidden disabled selected value>-- select --</option>
                        <option value='horizontal'>Horiz.</option>
                        <option value='vertical'>Vert.</option>
                        <option value='horizontalVertical'>Both</option>
                      </select>
                    </div>
                    <div class="field">
                      <label for="inputPosterize" class="label">Posterize</label>
                      <select id="inputPosterize" name="posterize" onChange={this.posterize}>
                        <option hidden disabled selected value>-- select --</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                      </select>
                    </div>
                    {/* <div class="field">
                      <label for="inputMode" class="label">Mode Select</label>
                      <select id="inputMode" name="mode" onChange={this.modeSelect}>
                        <option value="0">Default</option>
                        <option value="1">Shadow shapes</option>
                        <option value="2">Notan</option>
                      </select>
                    </div> */}
                  </div>
                </Collapse>
              </div>
            </div>
          </section>
        </div>
        <div id="sequenceButtons" style={window.screen.width >= 800 ? { position: 'fixed', top: 0, right: 200 } : { position: 'fixed', bottom: 0, left: 0 }}>
          <div class="buttons are-medium has-addons" style={{ margin: '10px', display: 'inline-block' }}>
            <button class="button" onClick={this.prevImg}>
              <span class="icon">
                <i class="fas fa-backward"></i>
              </span>
            </button>
            <button class="button" onClick={this.pauseSession} id="pause">
              <span class="icon">
                {/* TODO: this will need redone with the addition of state mgmt */}
                <i class="fas fa-play"></i>
              </span> </button>
            <button class="button" onClick={this.nextImg}>
              <span class="icon">
                <i class="fas fa-forward"></i>
              </span>
            </button>
            <button class="button" onClick={this.stopSession}>
              <span class="icon">
                <i class="fa fa-stop"></i>
              </span>
            </button>
            <button class="button" onClick={this.darkMode}>
              <span class="icon">
                <i class="far fa-lightbulb"></i>
              </span>
            </button>
            <button class="button" onClick={this.downloadImage}>
              <span class="icon">
                <i class="fas fa-save"></i>
              </span>
            </button>
            <button class="button" onClick={this.greatReset}>
              <span class="icon">
                <i class="fas fa-undo"></i>
              </span>
            </button>
          </div>
        </div>
        <div id="zeroth" class="modal is-large">
          <div class="modal-background" onClick={this.newBegin} ></div>
          <div class="modal-content">
            <div class="notification is-warning">
              There is no previous image.
            </div>
          </div>
        </div>

        <div id="last" class="modal is-large">
          <div class="modal-background" onClick={() => { document.getElementById('last').className = 'modal'; }} ></div>
          <div class="modal-content">
            <div class="notification is-link">
              This is the final image.
              </div>
          </div>
        </div>
      </div>
    );
  }

}

class Collapse extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cardState: this.props.isExpanded,
      isWide: this.props.isWide,
      isOnBottom: this.props.isOnBottom
    }
    this.toggleCardState = this.toggleCardState.bind(this)
  }

  toggleCardState() {
    this.setState({ cardState: !this.state.cardState })
  }

  render() {
    const { title, children } = this.props
    const { cardState, isWide, isOnBottom } = this.state

    return (
      <div className="column is-6" style={{ width: isWide ? '350px' : (isMobile ? '200px' : '185px'), margin: '0px', display: 'inline-block', padding: 0 }}>
        <div className="card" aria-hidden={cardState ? "false" : "true"}>
          <header
            className="card-header"
            style={{ cursor: "pointer" }}
            onClick={this.toggleCardState}>
            <p className="card-header-title">{title}</p>
            <a href className="card-header-icon">
              <span
                className="icon"
                style={{
                  transform: isOnBottom ? (cardState ? "rotate(180deg)" : null) : (cardState ? null : "rotate(180deg)"),
                  transition: "transform 250ms ease-out",
                }}>
                <i className="fa fa-angle-up"></i>
              </span>
            </a>
          </header>
          <div
            className="card-content"
            style={{
              maxHeight: cardState ? (window.innerWidth > 900 ? 1000 : 500) : 0,
              padding: cardState ? null : 0,
              overflow: "scroll",
              transition: "padding 250ms ease",
            }}>
            <div className="content">{children} </div>
          </div>
        </div>
      </div>
    )
  }
}

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

ReactDOM.render(
  <FileInput />
  , document.getElementById('root')
);
